import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "searchInput",
    "searchButton",
    "clearButton",
    "searchIcon",
    "spinner",
  ];

  connect() {
    this.sync();
  }

  sync() {
    if (!this.searchInputTarget || !this.searchButtonTarget) return;
    const value = this.searchInputTarget.value;
    this.searchButtonTarget.dataset.searchValue = value;
  }

  onKeyUp(event) {
    if (event.key === "Enter") {
      this.searchButtonTarget.click();
    }
  }

  toggleClear() {
    const isEmpty = this.searchInputTarget.value.trim() === "";
    this.clearButtonTarget.classList.toggle("is-hidden", isEmpty);
  }

  showSpinner() {
    this.searchIconTarget.classList.add("is-hidden");
    this.spinnerTarget.classList.remove("is-hidden");
  }

  hideSpinner() {
    this.searchIconTarget.classList.remove("is-hidden");
    this.spinnerTarget.classList.add("is-hidden");
  }
}
