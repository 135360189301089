import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["content", "icon"];

  toggle() {
    const isCollapsed = !this.contentTarget.classList.contains("is-collapsed");
    this.setCollapsedState(isCollapsed);
  }

  setCollapsedState(collapsed) {
    this.contentTarget.classList.toggle("is-collapsed", collapsed);
    this.iconTarget.classList.toggle("fa-chevron-down", collapsed);
    this.iconTarget.classList.toggle("fa-chevron-up", !collapsed);
  }
}
