// app/javascript/controllers/accounts_index_tour_controller.js
import BaseTourController from "./base_tour_controller";
import i18n from "../config/i18n";

export default class AccountsIndexTour extends BaseTourController {
  static values = {
    tourKey: String,
    defaultCurrency: String,
  };

  // Define specific steps for the 'accounts index' tour
  steps() {
    if (document.querySelector("article#no-accounts")) {
      return this.noAccountsSteps();
    } else {
      return this.accountsSteps();
    }
  }

  accountsSteps() {
    let steps = [
      {
        title: i18n.t("tour.welcome.title"), 
        text: i18n.t("tour.welcome.text"),
        buttons: [
          {
            action() {
              return this.cancel();
            },
            text: i18n.t("tour.buttons.cancel"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.account_details.title"),
        text: i18n.t("tour.account_details.text", {
          currency: this.defaultCurrencyValue,
        }),
        attachTo: {
          element: ".account-card",
          on: "top",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
    ];

    if (document.querySelector(".default-account-card")) {
      steps.push({
        title: i18n.t("tour.default_account.title"),
        text: i18n.t("tour.default_account.text"),
        attachTo: {
          element: ".default-account-card",
          on: "top",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.finish"),
          },
        ],
      });
    }

    return steps;
  }

  noAccountsSteps() {
    return [
      {
        title: i18n.t("tour.no_accounts_welcome.title"),
        text: i18n.t("tour.no_accounts_welcome.text"),
        buttons: [
          {
            action() {
              return this.cancel();
            },
            text: i18n.t("tour.buttons.cancel"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.confirm"),
          },
        ],
      },
      {
        title: i18n.t("tour.accounts_page.title"),
        text: i18n.t("tour.accounts_page.text"),
        attachTo: {
          element: "article.message.is-primary",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.create_account.title"),
        text: i18n.t("tour.create_account.text"),
        attachTo: {
          element: "a.new-account",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              document.querySelector("a.new-account").click();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
    ];
  }
}
