// app/javascript/controllers/categories_index_tour_controller.js
import BaseTourController from "./base_tour_controller";
import i18n from "../config/i18n";

export default class CategoriesIndexTour extends BaseTourController {
  // Define specific steps for the 'categories index' tour
  steps() {
    return [
      {
        title: i18n.t("tour.categories.title"),
        text: i18n.t("tour.categories.intro.text"),
        buttons: [
          {
            action() {
              return this.cancel();
            },
            text: i18n.t("tour.buttons.cancel"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.categories.title"),
        text: i18n.t("tour.categories.manage.text"),
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.finish"),
          },
        ],
      },
    ];
  }
}
