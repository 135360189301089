import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mockup", "template"];

  connect() {
    this.currentIndex = 0;

    // Check if there are mockups to cycle through
    if (this.mockupTargets.length > 0) {
      // Hide the template if mockups are present
      if (this.hasTemplateTarget) {
        this.templateTarget.classList.add("is-hidden");
      }

      // Show the first mockup
      this.showCurrentMockup();

      // Start cycling the mockups
      this.startCycle();
    }
  }

  disconnect() {
    // Clear interval when the controller is disconnected
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  startCycle() {
    // Cycle every 4 seconds
    this.interval = setInterval(() => {
      this.nextMockup();
    }, 4000);
  }

  showCurrentMockup() {
    // Hide all mockups except the current one
    this.mockupTargets.forEach((mockup, index) => {
      mockup.classList.toggle("is-active", index === this.currentIndex);
      mockup.classList.toggle("is-hidden", index !== this.currentIndex);
    });
  }

  nextMockup() {
    // Move to the next mockup or loop back to the first
    this.currentIndex = (this.currentIndex + 1) % this.mockupTargets.length;
    this.showCurrentMockup();
  }
}
