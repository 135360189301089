// controllers/iframe_resizer_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  resize(event) {
    const iframe = event.target;

    try {
      // Set overflow styles on the iframe
      iframe.style.overflow = 'hidden';
      
      if (iframe.contentWindow.document.body) {
        // Remove scrollbars from iframe content
        iframe.contentWindow.document.body.style.overflow = 'hidden';
        iframe.contentWindow.document.documentElement.style.overflow = 'hidden';
        
        // Calculate and set height
        const height = iframe.contentWindow.document.body.scrollHeight;
        iframe.style.height = `${height}px`;
        iframe.parentNode.style.height = `${height}px`;
      }
      
      console.log("Resized iframe to fit content.");
    } catch (error) {
      console.warn("Unable to resize iframe due to cross-origin restrictions.");
    }
  }
}