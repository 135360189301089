import i18n from "../config/i18n";

export const getChoicesConfig = (element) => ({
  searchEnabled: element.dataset.searchable === "true",
  removeItemButton: element.dataset.removable === "true",
  loadingText: i18n.t("choices.loadingText"),
  noResultsText: i18n.t("choices.noResultsText"),
  noChoicesText: i18n.t("choices.noChoicesText"),
  itemSelectText: null,
  uniqueItemText: i18n.t("choices.uniqueItemText"),
  customAddItemText: i18n.t("choices.customAddItemText"),
});
