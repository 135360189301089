import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeReflex(element, reflex, noop, reflexId) {
    // document.body.classList.add('wait')
    if (reflex.includes("Reflex#search")) {
      this.toggleSearchSpinner(element, true);
    }
    if (reflex.includes("Reflex#paginate")) {
      this.togglePaginateSpinner(element, true);
    }
  }

  reflexSuccess(element, reflex, noop, reflexId) {
    // show success message
  }

  reflexError(element, reflex, error, reflexId) {
    // show error message
  }

  reflexHalted(element, reflex, error, reflexId) {
    // handle aborted Reflex action
  }

  afterReflex(element, reflex, noop, reflexId) {
    // document.body.classList.remove('wait')
    if (reflex.includes("Reflex#search")) {
      this.toggleSearchSpinner(element, false);
    }

    // No need to revert, the page will reload the pagination element
    // if (reflex.includes("Reflex#paginate")) {
    //   this.togglePaginateSpinner(element, false);
    // }
  }

  finalizeReflex(element, reflex, noop, reflexId) {
    // all operations have completed, animation etc is now safe
  }

  toggleSearchSpinner(element, show) {
    // Find the button wrapper - could be either search or clear button
    const button = element.closest(".button") || element.closest("a");
    if (!button) return;

    // Find icon and spinner within the clicked element
    const iconWrapper =
      button.querySelector("span.icon") || button.querySelector("i");
    if (!iconWrapper) return;

    // For search button, toggle between search and spinner
    // For clear button, toggle between times and spinner
    const icon =
      iconWrapper.classList.contains("fa-search") ||
      iconWrapper.classList.contains("fa-times")
        ? iconWrapper
        : iconWrapper.querySelector(".fa-search, .fa-times");
    const spinner = iconWrapper.classList.contains("fa-spinner")
      ? iconWrapper
      : iconWrapper.querySelector(".fa-spinner");

    if (icon && spinner) {
      icon.classList.toggle("is-hidden", show);
      spinner.classList.toggle("is-hidden", !show);
    }
  }

  togglePaginateSpinner(element, show) {
    const paginateButton = element;

    if (paginateButton) {
      this.toggleIcon(paginateButton, show);
    }
  }

  toggleIcon(button, show) {
    const icons = button.querySelector("span.icon");
    const icon = icons.children[0];
    const spinner = icons.children[1];
    if (show) {
      icon.classList.add("is-hidden");
      spinner.classList.remove("is-hidden");
    } else {
      icon.classList.remove("is-hidden");
      spinner.classList.add("is-hidden");
    }
  }
}
