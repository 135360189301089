import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu", "button"];

  toggle(e) {
    e.stopPropagation();

    // Close all other dropdowns
    document.querySelectorAll(".dropdown.is-active").forEach((dropdown) => {
      if (dropdown !== this.element) {
        dropdown.classList.remove("is-active");
      }
    });

    // Toggle current dropdown
    this.element.classList.toggle("is-active");

    // Handle clicks outside
    if (this.element.classList.contains("is-active")) {
      document.addEventListener("click", (event) => {
        // Don't close if clicking inside the menu
        if (!this.menuTarget.contains(event.target)) {
          this.element.classList.remove("is-active");
        }
      });
    }
  }
}
