import { Controller } from "stimulus";
import Choices from "choices.js";
import { getChoicesConfig } from "../utils/choices_config";

export default class extends Controller {
  static targets = [
    "accountType",
    "interestRate",
    "creditLimit",
    "currencies",
    "accountNumber",
    "lastFour",
    "premiumMessage"
  ];

  connect() {
    // Initialize Choices.js
    this.accountTypeChoices = new Choices(
      this.accountTypeTarget,
      getChoicesConfig(this.accountTypeTarget)
    );

    // Add change event listener
    this.accountTypeTarget.addEventListener(
      "change",
      this.toggleFields.bind(this)
    );

    // Initialize Choices.js for existing currency selects
    this.currencyChoices = [];
    this.currenciesTarget.querySelectorAll("select").forEach((select) => {
      this.currencyChoices.push(new Choices(select, getChoicesConfig(select)));
    });

    this.toggleFields();
  }

  disconnect() {
    // Clean up Choices instances
    this.accountTypeChoices.destroy();
    this.currencyChoices.forEach((choice) => choice.destroy());
  }

  toggleFields() {
    const selectedValue = this.accountTypeTarget.value;

    if (selectedValue === "credit") {
      this.interestRateTarget.classList.remove("is-hidden");
      this.creditLimitTarget.classList.remove("is-hidden");
      this.accountNumberTarget.classList.add("is-hidden");
      this.lastFourTarget.classList.remove("is-hidden");
      this.premiumMessageTarget.classList.remove("is-hidden");
    } else if (selectedValue === "savings") {
      this.interestRateTarget.classList.remove("is-hidden");
      this.creditLimitTarget.classList.add("is-hidden");
      this.accountNumberTarget.classList.remove("is-hidden");
      this.lastFourTarget.classList.remove("is-hidden");
      this.premiumMessageTarget.classList.remove("is-hidden");
    } else if (selectedValue === "cash") {
      this.interestRateTarget.classList.add("is-hidden");
      this.creditLimitTarget.classList.add("is-hidden");
      this.accountNumberTarget.classList.add("is-hidden");
      this.lastFourTarget.classList.add("is-hidden");
      this.premiumMessageTarget.classList.add("is-hidden");
    } else {
      this.interestRateTarget.classList.add("is-hidden");
      this.creditLimitTarget.classList.add("is-hidden");
      this.accountNumberTarget.classList.remove("is-hidden");
      this.lastFourTarget.classList.remove("is-hidden");
      this.premiumMessageTarget.classList.remove("is-hidden");
    }
  }

  addCurrencyField(event) {
    event.preventDefault();

    // Find the last currency field's index
    let lastField = this.currenciesTarget.querySelector(
      ".account_balance_fields:last-child"
    );

    // Get the last choices instance and destroy it temporarily
    let lastChoices = this.currencyChoices.pop();
    lastChoices.destroy();

    // Clone the last currency field
    let newField = lastField.cloneNode(true);

    // Reinitialize choices on the original field
    const originalSelect = lastField.querySelector("select");
    this.currencyChoices.push(
      new Choices(originalSelect, getChoicesConfig(originalSelect))
    );

    let lastIndex = parseInt(
      lastField.querySelector("input, select").name.match(/\[(\d+)\]/)[1]
    );
    let newIndex = lastIndex + 1;

    // Get all selected currencies
    let selectedCurrencies = Array.from(
      this.currenciesTarget.querySelectorAll("select")
    ).map((select) => select.value);

    // Update the name and id attributes of the clone to have the new index
    newField.querySelectorAll("input, select").forEach((input) => {
      input.name = input.name.replace(/\[\d+\]/, `[${newIndex}]`);
      if (input.id) {
        let newId = input.id.replace(/_\d+_/, `_${newIndex}_`);
        input.id = newId;

        // Update label if it exists
        if (input.labels && input.labels.length > 0) {
          input.labels[0].setAttribute("for", newId);
        }
      }

      // Reset and enable input values for the new field
      if (input.type !== "hidden") {
        if (input.type === "checkbox" || input.type === "radio") {
          input.checked = false;
        } else if (input.tagName.toLowerCase() === "select") {
          // Remove choices-related elements
          input.className = input.className.replace(/choices.*?($|\s)/g, "");
          input.style = "";

          // Remove already selected currencies from the options
          Array.from(input.options).forEach((option) => {
            if (selectedCurrencies.includes(option.value)) {
              option.remove();
            }
          });
          input.value = "";
        } else {
          input.value = "0";
        }
        input.disabled = false;
      }
    });

    // Unhide the element with id currency-field-actions
    newField
      .querySelector("#currency-field-actions")
      .classList.remove("is-hidden");

    // Append the new field to the currencies container
    this.currenciesTarget.appendChild(newField);

    // Initialize Choices.js on the new select
    const newSelect = newField.querySelector("select");
    const newChoices = new Choices(newSelect, getChoicesConfig(newSelect));

    this.currencyChoices.push(newChoices);

    // Retrieve the value of the first option
    const firstOptionValue = newSelect.options[0].value;
    newChoices.setChoiceByValue(firstOptionValue);
  }

  removeCurrencyField(event) {
    event.preventDefault();
    if (
      this.currenciesTarget.querySelectorAll(".account_balance_fields").length >
      1
    ) {
      const fieldToRemove = event.target.closest(".account_balance_fields");
      const selectElement = fieldToRemove.querySelector("select");

      // Find and destroy the associated Choices instance
      const choiceIndex = this.currencyChoices.findIndex(
        (choice) => choice.passedElement.element === selectElement
      );
      if (choiceIndex > -1) {
        this.currencyChoices[choiceIndex].destroy();
        this.currencyChoices.splice(choiceIndex, 1);
      }

      fieldToRemove.remove();
    }
  }
}
