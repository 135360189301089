import ApplicationController from "./application_controller";
import Choices from "choices.js";
import { getChoicesConfig } from "../utils/choices_config";

export default class extends ApplicationController {
  static targets = ["select"];

  connect() {
    this.selectTargets.forEach((select) => this.initializeChoice(select));
  }

  disconnect() {
    this.selectTargets.forEach((select) => this.destroyChoice(select));
  }

  initializeChoice(select) {
    if (select.choices) return; // Already initialized

    const theme = select.dataset.choicesTheme;
    const alternate_theme = select.dataset.choicesAlternateTheme;

    const choices = new Choices(select, getChoicesConfig(select));
    select.choices = choices; // Store the instance on the element

    const container = select.parentElement.parentElement;

    if (theme) {
      container.classList.add(theme);
    }

    select.addEventListener("choice", () => {
      if (theme && alternate_theme) {
        container.classList.remove(theme);
        container.classList.add(alternate_theme);
      }
    });
  }

  destroyChoice(select) {
    if (!select.choices) return; // Not initialized

    select.choices.destroy();
    delete select.choices;
  }
}
